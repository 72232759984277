import { Button, Buttons, CancelButton, Checkbox, Drawer, Field, Form, FormMessage, Radio } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { Formik } from 'formik';
import { useActions, useDirtyCheck, useForm } from '~/hooks';
import React, { useEffect, useRef } from 'react';
import { emptyStringToNull, mergeValues } from '~/utils';
import * as Yup from 'yup';

const initialState = { isReady: false, opportunityTypes: null };
const handlers = {
  ready: ({ opportunityTypes }) => ({ isReady: true, opportunityTypes }),
};

export default function SyncSettingsDrawer({ integration, onClose, onSave }) {
  const formRef = useRef();
  const dirtyCheck = useDirtyCheck(() => formRef.current.dirty);
  const [{ status, message, isSubmitting }, form] = useForm();
  const [{ isReady, opportunityTypes }, actions] = useActions(handlers, initialState);
  const api = useApi();
  const { workspace } = useWorkspace();

  useEffect(() => {
    (async () => {
      const { data: opportunityTypes } = await api.www.workspaces(workspace.id).salesforce.getOpportunityTypes();
      actions.ready({ opportunityTypes });
    })();
  }, [actions, api, workspace.id]);

  function handleClose() {
    if (onClose) {
      onClose();
    }
  }

  const initialValues = mergeValues(
    {
      autoSync: false,
      syncStartDate: '',
      minimumProbability: '0',
      minimumAmount: '0',
      opportunityTypes: [],
    },
    integration.settings,
  );
  if (!isReady) return null;
  return (
    <Drawer
      isOpen
      title="Sync Settings"
      byline="Salesforce Integration"
      onBeforeClose={({ setIsOpen }) => dirtyCheck(() => setIsOpen(false))}
      onClose={handleClose}>
      {(closeDrawer) => {
        const handleCloseClick = () => dirtyCheck(() => closeDrawer());

        async function handleSubmit(values) {
          try {
            form.submit();

            const body = emptyStringToNull({
              ...values,
            });

            await api.www.workspaces(workspace.id).salesforce.updateSyncSettings(body);

            form.save();
            onSave();
            closeDrawer();
          } catch ({ message }) {
            form.error({ message });
          }
        }

        return (
          <Formik
            innerRef={formRef}
            enableReinitialize
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={Yup.object().shape({
              autoSync: Yup.bool().label('Automatically Sync').required(),
              minimumProbability: Yup.number().label('Minimum Probability').min(0).max(100).nullable(),
              minimumAmount: Yup.number().label('Minimum Amount').min(0).max(10000000000000).nullable(),
              syncStartDate: Yup.date().label('Start Date').nullable().required(),
            })}>
            {(formik) => {
              return (
                <Form>
                  {status && <FormMessage.Error>{message}</FormMessage.Error>}
                  <Form.Section
                    title="Automatically Sync"
                    subtitle="If turned on, every hour changes in your Salesforce connection will sync changes to Ruddr.">
                    <Form.Control>
                      <Field.RadioGroup name="autoSync">
                        <Radio value={true} label="On" />
                        <Radio value={false} label="Off" />
                      </Field.RadioGroup>
                    </Form.Control>
                  </Form.Section>
                  <Form.Section
                    title="Beginning"
                    subtitle="Any accounts, opportunities, and contacts created prior to the Start Date  will not be synced.">
                    <Form.Control>
                      <Field.DayPicker name="syncStartDate" placeholder="Start Date" clearable={false} />
                    </Form.Control>
                  </Form.Section>
                  <Form.Section
                    title="Probability"
                    subtitle="Any opportunities with less than these minimum probability amount values will not be synced.">
                    <Form.Control>
                      <Field.Number
                        name="minimumProbability"
                        placeholder="Minimum Probability"
                        min={0}
                        max={100}
                        precision={2}
                        prefix="%"
                        style={{ textAlign: 'left' }}
                      />
                    </Form.Control>
                    <Form.Control>
                      <Field.Number
                        name="minimumAmount"
                        placeholder="Minimum Amount"
                        min={0}
                        precision={2}
                        prefix="$"
                      />
                    </Form.Control>
                  </Form.Section>
                  {opportunityTypes && (
                    <Form.Section
                      title="Opportunity Types"
                      subtitle="Any opportunities with the selected types will be synced.">
                      <Form.Control>
                        <Field.CheckboxGroup direction="vertical" name="opportunityTypes">
                          {opportunityTypes.map((option, index) => (
                            <Checkbox key={index} value={option} label={option} />
                          ))}
                        </Field.CheckboxGroup>
                      </Form.Control>
                    </Form.Section>
                  )}
                  {!opportunityTypes && <>Loading opportunity types from Salesforce...</>}
                  <Drawer.Actions>
                    <Buttons align="right">
                      <CancelButton onClick={handleCloseClick}>Close</CancelButton>
                      <Button isLoading={isSubmitting} onClick={formik.submitForm}>
                        Save &amp; Close
                      </Button>
                    </Buttons>
                  </Drawer.Actions>
                </Form>
              );
            }}
          </Formik>
        );
      }}
    </Drawer>
  );
}
