import moment from 'moment';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { Button, Buttons, DateTime, DeleteButton, Icon, Tooltip } from '~/components';
import { useIsMounted } from '~/hooks';
import { colors, weights } from '~/styles';
import DeleteDataConfirmation from './DeleteDataConfirmation';
import ManualSync from './ManualSync';
import MemberMapping from './MemberMapping';
import MemberPreview from './MemberPreview';
import MemberSettings from './MemberSettings';
import SyncResults from './SyncResults';
import SyncSettings from './SyncSettings';
import TimeOffSettings from './TimeOffSettings';
import TimeOffTypeMapping from './TimeOffTypeMapping';
import TimeOffTypePreview from './TimeOffTypePreview';

const REFRESH_DELAY_SECONDS = 5;

const Blocks = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 64rem;
  margin: -1rem;
  margin-top: 1rem;
`;

const Block = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: calc(50% - 2rem);
  margin: 1rem;
  padding: 1.5rem;
  background-color: ${colors.white};
  box-shadow: 0 0.1875rem 1rem ${colors.grey10};
  border-radius: 0.3125rem;
`;

const FullBlock = styled(Block)`
  width: calc(100% - 2rem);
`;

const TitleRow = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.h3`
  font-size: 1.5rem;
  font-weight: ${weights.light};
`;

const RefreshButton = styled.div`
  margin-left: auto;
  padding-left: 1rem;
  color: ${({ disabled }) => (disabled ? colors.grey25 : colors.black)};
  cursor: pointer;
`;

const Actions = styled.div`
  margin-top: auto;
  padding-top: 1rem;
`;

const Table = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
`;

const Row = styled.div`
  display: flex;
  align-items: center;

  &:not(:last-child) {
    border-bottom: 1px solid ${colors.grey10};
  }
`;

const Attribute = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
`;

const Value = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  font-weight: ${weights.bold};
`;

export default function BambooSettings({ integration, reloadIntegration }) {
  const isMounted = useIsMounted();

  const [forceRefreshSyncsDate, setForceRefreshSyncsDate] = useState(moment().format());
  const [showDeleteData, setShowDeleteData] = useState(false);
  const [showManualSync, setShowManualSync] = useState(false);
  const [showMemberMapping, setShowMemberMapping] = useState(false);
  const [showMemberPreview, setShowMemberPreview] = useState(false);
  const [showMemberSettings, setShowMemberSettings] = useState(false);
  const [showSyncSettings, setShowSyncSettings] = useState(false);
  const [showTimeOffTypeMapping, setShowTimeOffTypeMapping] = useState(false);
  const [showTimeOffTypePreview, setShowTimeOffTypePreview] = useState(false);
  const [showTimeOffSettings, setShowTimeOffSettings] = useState(false);
  const [canRefresh, setCanRefresh] = useState(true);

  const domainUrl = useMemo(() => `${integration.info.domain}.bamboohr.com`, [integration]);

  const reloadIntegrationWithDelay = () => {
    if (!canRefresh) {
      return;
    }
    setCanRefresh(false);
    reloadIntegration();
    setTimeout(() => {
      if (isMounted.current) {
        setCanRefresh(true);
      }
    }, REFRESH_DELAY_SECONDS * 1000);
  };

  return (
    <>
      <Blocks>
        <FullBlock>
          <Title>Connection Information</Title>
          <Table>
            <Row>
              <Attribute>BambooHR Domain</Attribute>
              <Value>
                <a href={`https://${domainUrl}`} target="_blank" rel="noopener noreferrer">
                  {domainUrl}
                </a>
              </Value>
            </Row>
            <Row>
              <Attribute>API Key</Attribute>
              <Value>******</Value>
            </Row>
          </Table>
        </FullBlock>
        <Block>
          <Title>Member Settings</Title>
          <Table>
            <Row>
              <Attribute>Invite Created Members</Attribute>
              <Value>{integration.settings.inviteMembers ? 'On' : 'Off'}</Value>
            </Row>
            <Row>
              <Attribute>Create Billable Members</Attribute>
              <Value>{integration.settings.setBillable ? 'On' : 'Off'}</Value>
            </Row>
            <Row>
              <Attribute>Update Member Status</Attribute>
              <Value>{integration.settings.updateMemberStatus ? 'On' : 'Off'}</Value>
            </Row>
            <Row>
              <Attribute>Update Active Dates</Attribute>
              <Value>{integration.settings.updateActiveDates ? 'On' : 'Off'}</Value>
            </Row>
            <Row>
              <Attribute>Update Name</Attribute>
              <Value>{integration.settings.updateName ? 'On' : 'Off'}</Value>
            </Row>
            <Row>
              <Attribute>Update Job Title</Attribute>
              <Value>{integration.settings.updateJobTitle ? 'On' : 'Off'}</Value>
            </Row>
            <Row>
              <Attribute>Update Manager</Attribute>
              <Value>{integration.settings.updateManager ? 'On' : 'Off'}</Value>
            </Row>
            <Row>
              <Attribute>Update Internal ID</Attribute>
              <Value>{integration.settings.updateInternalId ? 'On' : 'Off'}</Value>
            </Row>
            <Row>
              <Attribute>Member Mapping</Attribute>
              <Value>{integration.settings.autoMemberMapping ? 'Automatic' : 'Manual'}</Value>
            </Row>
          </Table>
          <Actions>
            <Buttons>
              <Button onClick={() => setShowMemberSettings(true)}>Change Settings</Button>
              {integration.settings.autoMemberMapping ? (
                <Button onClick={() => setShowMemberPreview(true)}>Preview Members</Button>
              ) : (
                <Button onClick={() => setShowMemberMapping(true)}>Edit Mappings</Button>
              )}
            </Buttons>
          </Actions>
        </Block>
        <Block>
          <Title>Time Off Settings</Title>
          <Table>
            <Row>
              <Attribute>Import Start Date</Attribute>
              <Value>
                <DateTime value={integration.settings.timeOffStartDate} format="l" titleFormat="LL" />
              </Value>
            </Row>
            <Row>
              <Attribute>Time Off Type Mapping</Attribute>
              <Value>{integration.settings.autoTimeOffTypeMapping ? 'Automatic' : 'Manual'}</Value>
            </Row>
            <Row>
              <Attribute>Create Time Off Types</Attribute>
              <Value>{integration.settings.createTimeOffTypes ? 'On' : 'Off'}</Value>
            </Row>
          </Table>
          <Actions>
            <Buttons>
              <Button onClick={() => setShowTimeOffSettings(true)}>Change Settings</Button>
              {integration.settings.autoTimeOffTypeMapping ? (
                <Button onClick={() => setShowTimeOffTypePreview(true)}>Preview Time Off Types</Button>
              ) : (
                <Button onClick={() => setShowTimeOffTypeMapping(true)}>Edit Mappings</Button>
              )}
            </Buttons>
          </Actions>
        </Block>
        <Block>
          <Title>Sync Settings</Title>
          <Table>
            <Row>
              <Attribute>Automatically Sync</Attribute>
              <Value>{integration.settings.autoSync ? 'On' : 'Off'}</Value>
            </Row>
            <Row>
              <Attribute>Sync Members</Attribute>
              <Value>{integration.settings.syncMembers ? 'On' : 'Off'}</Value>
            </Row>
            <Row>
              <Attribute>Sync Time Off Entries</Attribute>
              <Value>{integration.settings.syncTimeOff ? 'On' : 'Off'}</Value>
            </Row>
          </Table>
          <Actions>
            <Buttons>
              <Button onClick={() => setShowSyncSettings(true)}>Change Settings</Button>
              <Button onClick={() => setShowManualSync(true)}>New Manual Sync</Button>
            </Buttons>
          </Actions>
        </Block>
        <Block>
          <TitleRow>
            <Title>Imported Data</Title>
            <RefreshButton disabled={!canRefresh} onClick={() => reloadIntegrationWithDelay()}>
              <Tooltip message="Refresh data counts">
                <Icon icon="sync" />
              </Tooltip>
            </RefreshButton>
          </TitleRow>
          <Table>
            <Row>
              <Attribute>Time Off Entries</Attribute>
              <Value>{integration.data.timeEntries}</Value>
            </Row>
          </Table>
          <Actions>
            <DeleteButton onClick={() => setShowDeleteData(true)}>Delete Time Off Entries</DeleteButton>
          </Actions>
        </Block>
        <FullBlock>
          <SyncResults refreshDate={forceRefreshSyncsDate} />
        </FullBlock>
      </Blocks>
      {showManualSync && (
        <ManualSync
          integration={integration}
          onClose={() => setShowManualSync(false)}
          onFinish={() => setForceRefreshSyncsDate(moment().format())}
        />
      )}
      {showMemberMapping && (
        <MemberMapping onClose={() => setShowMemberMapping(false)} onSave={() => reloadIntegration()} />
      )}
      {showMemberPreview && <MemberPreview onClose={() => setShowMemberPreview(false)} />}
      {showMemberSettings && (
        <MemberSettings
          integration={integration}
          onClose={() => setShowMemberSettings(false)}
          onSave={() => reloadIntegration()}
        />
      )}
      {showSyncSettings && (
        <SyncSettings
          integration={integration}
          onClose={() => setShowSyncSettings(false)}
          onSave={() => reloadIntegration()}
        />
      )}
      {showTimeOffSettings && (
        <TimeOffSettings
          integration={integration}
          onClose={() => setShowTimeOffSettings(false)}
          onSave={() => reloadIntegration()}
        />
      )}
      {showTimeOffTypeMapping && (
        <TimeOffTypeMapping onClose={() => setShowTimeOffTypeMapping(false)} onSave={() => reloadIntegration()} />
      )}
      {showTimeOffTypePreview && (
        <TimeOffTypePreview integration={integration} onClose={() => setShowTimeOffTypePreview(false)} />
      )}
      {showDeleteData && (
        <DeleteDataConfirmation onClose={() => setShowDeleteData(false)} onDelete={() => reloadIntegration()} />
      )}
    </>
  );
}
